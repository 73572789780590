@import "../../_globalColor"; // Adjust the path as per your file structure

.social-media-div {
  font-size: 2em;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent line breaks */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.icon-button {
  display: inline-block;
  margin-right: 10px; /* Adjust spacing between icons */
}

.icon-button:last-child {
  margin-right: 0; /* Remove margin from the last icon */
}

.icon-button i {
  color: $textColorDark;
  border-radius: 2.6rem;
  cursor: pointer;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  width: 2.6rem;
  margin: 0;
  transition: 0.2s ease-in;
}

/* Define background colors for each social media icon */
.facebook i {
  background-color: $faceBook;
}

.linkedin i {
  background-color: $linkedin;
}

.github i {
  background-color: $github;
}

.gitlab i {
  background-color: $gitlab;
}

.google i {
  background-color: $google;
}

.twitter i {
  background-color: $twitter;
}

.medium i {
  background-color: $medium;
}

.stack-overflow i {
  background-color: $stackoverflow;
}

.kaggle i {
  background-color: $kaggle;
}

.instagram i {
  background-color: $instagram;
}

.leetcode i {
  background-color: "#ffa116";
}
.icon-button.leetcode {
  margin-top: 5px;
  display: inline-block;
  vertical-align: middle; /* Align icons vertically in the middle */
  width: 41.594px; /* Set a fixed width */
  height: 41.594px; /* Set a fixed height */
  margin-right: 10px; /* Adjust spacing between icons */
  border-radius: 50%; /* Ensure the button is circular */
  position: relative; /* Add position relative */
}

.icon-button.leetcode .leetcode-icon {
  position: absolute; /* Add position absolute */
  top: 50%; /* Move the icon to the center vertically */
  left: 50%; /* Move the icon to the center horizontally */
  transform: translate(-50%, -50%); /* Center the icon */
  width: 100%; /* Occupy the entire width of the button */
  height: 100%; /* Occupy the entire height of the button */
  display: flex; /* Center the icon vertically and horizontally */
  align-items: center;
  background-color: $leetcode; /* Apply background color to the button */
  border-radius: 50%;
  justify-content: center;
}

.leetcode-icon {
  margin-bottom: 10px;
}

.icon-button.leetcode .leetcode-icon svg {
  vertical-align: middle; 
  width: 53%;
  height: auto; /* Allow the height to adjust proportionally */
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .social-media-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}